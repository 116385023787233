import React, { useState, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import EASA from "../assets/CAMO/easa.svg";
import HighlyExperienced from "../assets/CAMO/highly-experienced.svg";
import Delivery from "../assets/CAMO/icon-camo-delivery.svg";
import FleetStatus from "../assets/CAMO/icon-camo-fleet-status.svg";
import Maintenance from "../assets/CAMO/icon-camo-maintenance.svg";
import Monitoring from "../assets/CAMO/icon-camo-monitoring.svg";
import Records from "../assets/CAMO/icon-camo-records.svg";
import TechSupport from "../assets/CAMO/icon-camo-tech-support.svg";
import BusinessAircraft from "../assets/CAMO/business-aircraft.svg";
import CommercialAircraft from "../assets/CAMO/airbus-maintenance.svg";
import FutureReady from "../assets/CAMO/ready-for-the-future.svg";
import DigitalTC from "../assets/TaskCards/digital-task-cards.svg";
import CAMP from "../assets/camp-logo@2x.png";
import AMOS from "../assets/amos_logo.png";
import Centrik from "../assets/centrik_logo.png";
import Tick from "../assets/icon-tick.svg";
import TechCard from "../components/TechLog/techCard";
import Started from "../components/Layout/started";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Seamless from "../components/Layout/seamlessIntegration";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import pageStyles from "./page.module.less";
import styles from "./taskcards.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";

const ExperiencedCard = {
  img: HighlyExperienced,
  title: "Highly Experienced",
  text:
    "We have a market-leading, highly experienced team comprising the full spectrum of engineering staff covering the range of CAMO support activities."
};

const EASACard = {
  img: EASA,
  title: "Part-M Subpart G Approved",
  text:
    "Recognised by the UK Civil Aviation Authority and EASA for highly competent and capable leadership in compliance monitoring, management and continuing airworthiness."
};

const FutureCard = {
  img: FutureReady,
  title: "Ready For The Future",
  text:
    "The deep integrations we have throughout our service portfolio ensure that we can offer a modern and future-proof service."
};

const cardsArray = [ExperiencedCard, EASACard, FutureCard];

const StyledImage = styled.img`
  height: 35px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  span {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #242d41;
  }
  @media screen and (max-width: 576px) {
    justify-content: center;
  }
`;

const TinyText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: rgba(36, 45, 65, 0.7);
`;

const TopCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  padding-right: 30px;
  @media screen and (max-width: 576px) {
    margin-bottom: 30px;
    justify-content: center;
    padding-right: 0;
    text-align: center;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  ${props => props.left && "justify-content: left"}
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 150px;
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
`;

const TickTitle = styled.h5`
  color: #242d41;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 15px;
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    text-align: left;
    margin-top: 20px;
  }
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

export default props => {
  const cards = cardsArray.map((card, key) => (
    <Col md={4} className={`${styles.techCardCol} ${pageStyles.coliPad}`}>
      <ScrollAnimation
        animateOnce
        animateIn={
          key === 0 ? "fadeInLeft" : key === 1 ? "fadeInUp" : "fadeInRight"
        }
        offset={0}
      >
        <TechCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
        />
      </ScrollAnimation>
    </Col>
  ));
  const { bgImg } = props.data;

  const seamlessCards = [
    {
      img: AMOS,
      title: "AMOS"
    },
    {
      title: "And more"
    },
    {
      img: Centrik,
      title: "Centrik"
    },
    {
      img: CAMP,
      title: "CAMP"
    }
  ];

  return (
    <Layout>
      <SEO
        title="Continuing Airworthiness Management"
        description="Technical expertise combined with the latest technologies enabling efficient aircraft maintenance."
      />
      <PageHeader
        header="Continuing Airworthiness Management"
        subHeader="Technical expertise combined with the latest technologies enabling efficient aircraft maintenance."
        mediumHeader
        bgImg={bgImg}
        tag="EASA Part-M (UK.MG.0750), Jersey & Guernsey approved"
        tagStrong="Part-M"
        longTag
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={12}>
            <Row className={styles.titleRow}>
              <Col md={12}>
                <CenteredDiv>
                  <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                    <h3 className={styles.title}>
                      Your experts in airworthiness
                    </h3>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
              <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
                <CenteredDiv>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeIn"
                    offset={0}
                    delay={200}
                  >
                    <h4 className={styles.subTitle}>
                      At TrustFlight, we pride ourselves in providing a range of
                      flexible and efficient services for the management of
                      airworthiness. As one of the world’s largest outsourced
                      CAMO providers, we have the experience and skills to
                      efficiently support aircraft from business jets all the
                      way to wide-body airliners.
                    </h4>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={10} md={12}>
            <Row>{cards}</Row>
            <Row className={`${styles.titleRow} ${pageStyles.pageParentRow}`}>
              <Col md={12}>
                <CenteredDiv>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInDown"
                    offset={0}
                  >
                    <h3 className={styles.title}>Our Services</h3>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
              <Col md={{ span: 8, offset: 2 }}>
                <CenteredDiv>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                    <h4 className={styles.subTitle}>
                      Leveraging the benefits of digitisation and automated data
                      verification, combined with our years of expertise, we are
                      able to provide a range of flexible and efficient services
                      at a competitive price point.
                    </h4>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={9} md={11}>
            <Row>
              <Col md={4} sm={6}>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Maintenance} />
                      <span>Maintenance Programmes</span>
                    </CardHeader>
                    <TinyText>
                      Monitoring and development of tailored maintenance
                      programmes.
                    </TinyText>
                  </TopCardWrapper>
                </ScrollAnimation>
              </Col>
              <Col md={4} sm={6}>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={FleetStatus} />
                      <span>Fleet Support</span>
                    </CardHeader>
                    <TinyText>
                      Short and long-term planning of maintenance inputs,
                      on-site maintenance representation.
                    </TinyText>
                  </TopCardWrapper>
                </ScrollAnimation>
              </Col>
              <Col md={4} sm={6}>
                <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={TechSupport} />
                      <span>Technical Support</span>
                    </CardHeader>
                    <TinyText>
                      Guidance on AD/SB's, damage assessment and repairs,
                      modifications, defects and OEM liaison.
                    </TinyText>
                  </TopCardWrapper>
                </ScrollAnimation>
              </Col>
              <Col md={4} sm={6}>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Records} />
                      <span>Aircraft Records</span>
                    </CardHeader>
                    <TinyText>
                      Technical records audits, management and digitisation.
                    </TinyText>
                  </TopCardWrapper>
                </ScrollAnimation>
              </Col>
              <Col md={4} sm={6}>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Monitoring} />
                      <span>Reliability Monitoring</span>
                    </CardHeader>
                    <TinyText>
                      Monitoring and assessment of aircraft reliability. ETOPS
                      monitoring and approval.
                    </TinyText>
                  </TopCardWrapper>
                </ScrollAnimation>
              </Col>
              <Col md={4} sm={6}>
                <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Delivery} />
                      <span>Delivery and Redelivery</span>
                    </CardHeader>
                    <TinyText>
                      Aircraft acceptance, technical audits and mid-term/end of
                      lease inspections.
                    </TinyText>
                  </TopCardWrapper>
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Container>
          <Row className={`${styles.titleRow} ${pageStyles.pageParentRow}`}>
            <Col md={12}>
              <CenteredDiv>
                <ScrollAnimation
                  animateOnce
                  animateIn="fadeInDown"
                  offset={0}
                >
                  <h3 className={styles.title}>Our Experience</h3>
                </ScrollAnimation>
              </CenteredDiv>
            </Col>
            <Col md={{ span: 8, offset: 2 }}>
              <CenteredDiv>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <h4 className={styles.subTitleExperience}>
                    We have a market-leading highly experienced team covering the full spectrum of CAMO support activities for both operating and stored commercial or business aircraft.
                  </h4>
                </ScrollAnimation>
              </CenteredDiv>
            </Col>
          </Row>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col md={6} className={`${pageStyles.coliPad} ${styles.mobileImage}` }>
              <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                <img
                  src={CommercialAircraft}
                  className={styles.typesImageCommercial}
                />
              </ScrollAnimation>
            </Col>
            <Col md={{ span: 5, offset: 1 }} className={pageStyles.coliPad}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                <h1 className={styles.aircraftTypeTitle}>Commercial Aircraft</h1>
                <h4 className={`${styles.subTitle} ${styles.subTitleRight}`}>
                  Our background is unique with a team established from an airline and with ongoing experience supporting a variety of operations including scheduled passenger airlines, cargo and ad-hoc operations.
                </h4>
                <Row>
                  <Col className={styles.colAircraftTypes}>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span><span>Airbus</span><br />A318, A319, A320, A321 and A330</span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span><span>Boeing</span><br />B737, B757, B767 and B787</span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span><span>Regulatory Authorities</span><br />EASA, CASA, GCAA, FAA</span>
                    </div>
                  </Col>
                </Row>
              </ScrollAnimation>
            </Col>
            <Col md={6} className={`${pageStyles.coliPad} ${styles.nonMobileImage}`}>
              <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                <img
                  src={CommercialAircraft}
                  className={styles.typesImageCommercial}
                />
              </ScrollAnimation>
            </Col>
          </Row>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRowBusiness}`}
          >
            <Col md={6} className={pageStyles.coliPad}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                <img
                  src={BusinessAircraft}
                  className={styles.typesImageBusiness}
                />
              </ScrollAnimation>
            </Col>
            <Col md={6} className={pageStyles.coliPad}>
              <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                <h1 className={styles.aircraftTypeTitle}>Business Aircraft</h1>
                <h4 className={`${styles.subTitle} ${styles.subTitleLeft}`}>
                  Our business aircraft capability is the result of our experience being an operator of aircraft combined with our commercial air transport background. Through this we are able to provide airline levels of safety, quality and cost discipline to your fleet.
                </h4>
                <Row>
                  <Col className={styles.colAircraftTypes}>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span><span>Embraer</span><br />Phenom 100 & 300, Legacy 450 & 500, Praetor 500 & 600</span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span><span>Cessna Citation</span><br />CJ1/M2, CJ2, CJ3, CJ4, Excel, XLS</span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span><span>Bombardier</span><br />Challenger 300 & 350, Challenger 604 & 605, Global Express</span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span><span>Regulatory Authorities</span><br />EASA, CASA, GCAA, FAA</span>
                    </div>
                  </Col>
                </Row>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </GradientSection>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Seamless
              title="Highly experienced with industry-leading aviation maintenance management software."
              text="Ensuring that we manage your assets in the most effective way for your organisation. "
              cards={seamlessCards}
            />
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={8} md={10}>
            <Row>
              <Col>
                <ScrollAnimation animateOnce animateIn="fadeInDown" offset={0}>
                  <h3 className={styles.title}>Digital CAMO</h3>
                </ScrollAnimation>
              </Col>
            </Row>
            <CenteredDiv>
              <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                <h5 className={styles.subTitle}>
                  As a digital software provider, our CAMO services leverage all
                  of the benefits offered by digital records and modern
                  software. This ensures that our customers not only have access
                  to our portfolio of software products, including electronic
                  Tech Logs and Task Cards, but we also have the expertise in
                  data analytics to provide unparalleled insights into your
                  aircraft.
                </h5>
              </ScrollAnimation>
            </CenteredDiv>
          </Col>
          <Col lg={8} md={10} xs={10} className={styles.colFullImg}>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
              <img src={DigitalTC} alt="DigitalTaskCards" />
            </ScrollAnimation>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        />
        <Started
          title="Let's Start the Conversation"
          text="Talk to our CAMO team to find out how you can benefit from our Technical Services."
          buttonText="Interested in CAMO services?"
        />
      </Container>
      <AlsoInterestedIn page="camo" />
    </Layout>
  );
};

export const query = graphql`
  query CAMOQuery {
    bgImg: file(relativePath: { eq: "CAMO/camo-featured@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
